import {
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBRow,
  MDBSpinner,
  MDBValidation,
} from "mdb-react-ui-kit";
import React, { useCallback, useState } from "react";

import useStore from "../Store";
import { createUserReq } from "../Request";

const CreateUserForm = () => {
  const access_token = useStore((s) => s.access_token);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const initialFormValues = {
    first_name: "",
    last_name: "",
    email: "",
    affiliateCode: "",
    password: "",
    role: "user",
    commission: "10",
    commission_type: "percentage"
  };

  const [formData, setFormData] = useState(initialFormValues);

  const changeValue = useCallback(
    (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData]
  );

  const createUser = async () => {
    setLoading(true);
    //console.log("f", formData)
    const createdUser = await createUserReq(access_token, formData);

    if (createdUser.error) {
      console.log(createdUser);
      setError(createdUser.message);
      setSuccess(null);
      setLoading(false);
      return;
    }

    setError(null);
    setSuccess("Awesome! The User created successfully!");
    //setFormData(initialFormValues);
    setLoading(false);
  };

  const handleSubmit = useCallback(() => {
    createUser();
  }, [formData]);

  return (
    <MDBValidation className="row g-3" noValidate onSubmit={handleSubmit}>
      <MDBCol size="12">
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        {success && (
          <div className="alert alert-success copy-alert" role="alert">
            {success}
          </div>
        )}
      </MDBCol>
      <MDBCol size="6">
        <MDBInput
          label="First Name"
          className="text-white"
          id="createUserFirstName"
          type="text"
          name="first_name"
          value={formData.first_name}
          onChange={changeValue}
          required
        />
      </MDBCol>
      <MDBCol size="6">
        <MDBInput
          label="Last Name"
          className="text-white"
          id="form1"
          type="text"
          name="last_name"
          value={formData.last_name}
          onChange={changeValue}
          required
        />
      </MDBCol>
      <MDBCol size="6">
        <MDBInput
          label="Email"
          id="form1"
          className="text-white"
          type="email"
          name="email"
          value={formData.email}
          onChange={changeValue}
          validation="Required"
          required
          invalid
        />
      </MDBCol>
      <MDBCol size="6">
        <MDBInput
          label="Password"
          id="form1"
          type="text"
          className="text-white"
          name="password"
          value={formData.password}
          onChange={changeValue}
          validation="Required"
          required
          invalid
        />
      </MDBCol>
      <MDBCol size="6">
        <MDBInput
          label="Affiliate Code"
          id="form1"
          type="text"
          className="text-white"
          name="affiliateCode"
          value={formData.affiliateCode}
          onChange={changeValue}
          validation="Required"
          required
          invalid
        />
      </MDBCol>
      {/* <MDBCol size="6">
        <MDBInput
          label="Commission (%)"
          id="form1"
          type="text"
          name="commission"
          value={formData.commission}
          onChange={changeValue}
          validation="Required"
          required
          invalid
        />
      </MDBCol> */}
      <MDBCol size="6">
        <div style={{ position: "relative" }}>
          <MDBInput
            label="Commission"
            id="form1"
            type="text"
            className="text-white"
            name="commission"
            value={formData.commission}
            onChange={changeValue}
            validation="Required"
            required
            invalid
          />
          <select
            className="inline-select"
            value={formData.commission_type}
            onChange={changeValue}
            name="commission_type"
          >
            <option value="percentage">&#37;</option>
            <option value="flat">&#36;</option>
          </select>
        </div>
      </MDBCol>
      
      <MDBCol size="6">
        
      </MDBCol>
      <MDBCol size="6 text-end">
        <MDBBtn type="submit" className="text-white btn-pink" disabled={loading}>
          {!loading ? "Create" : "Creating..."}
        </MDBBtn>
      </MDBCol>
    </MDBValidation>
  );
};

export default CreateUserForm;
