import { MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdb-react-ui-kit";
import React from "react";

const SectionWrapper = ({ title, children }) => {
  return (
    <section className="py-5 text-start">
      <MDBContainer>
        <MDBRow>
          <div className="shadow-5 p-4 rounded-3 bg-dark">
            {title && (
              <MDBRow>
                <MDBCol>
                  <MDBTypography variant="h4" className="mb-4 text-white">
                    {title}
                  </MDBTypography>
                </MDBCol>
              </MDBRow>
            )}
            {children}
          </div>
        </MDBRow>
      </MDBContainer>
    </section>
  );
};

export default SectionWrapper;
