import React from "react";

export default function TableUtilities({
  changeLimit,
  page,
  limit,
  totalCount,
  nextPage,
  previousPage,
  pageLength,
  lastPage,
}) {


  const limitt = limit===undefined ? 10 : limit
  const startingPoint = (parseInt(page) - 1) * parseInt(limitt) + 1

  return (
    <div className="d-flex justify-content-end align-items-center py-4 history-page-utility">
      <span>Rows per page</span>
      <select onChange={changeLimit} value={limit}>
        <option>10</option>
        <option>20</option>
        <option>30</option>
      </select>
      
      <span>
        {startingPoint} - {pageLength} of{" "}
        {totalCount}
      </span>
      <span>
        <a
          onClick={previousPage}
          className={`pagination-button d-inline-block ${
            page > 1 ? "text-white" : "text-muted"
          }`}
        >
          <i className="fas fa-chevron-left"></i>
        </a>
      </span>
      <span>
        <a
          onClick={nextPage}
          className={`pagination-button d-inline-block ${
            page < lastPage ? "text-white" : "text-muted"
          }`}
        >
          <i className="fas fa-chevron-right"></i>
        </a>
      </span>
    </div>
  );
}
