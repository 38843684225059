import axios from "axios";

const server = process.env.REACT_APP_SERVER_ADDR;
//console.log('server', server)



export const loginReq = async (loginDetails) => {
  try {
    const res = await axios.post(`${server}/api/users/admin/login`, loginDetails, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      return {
        error: true,
        status: error.response.status,
      };
    }
  }
};

export const profileReq = async (access_token) => {
  try {
    const res = await axios.get(`${server}/api/users/profile`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      return {
        error: true,
        status: error.response.status,
      };
    }
  }
};

export const allUsersReq = async (access_token, search = "", page=1, limit=10) => {
  try {
    const res = await axios.get(
      `${server}/api/users?search=${search}&page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    if (error.response) {
      return {
        error: true,
        status: error.response.status,
      };
    }
  }
};

export const createUserReq = async (access_token, newUserDetails) => {
  try {
    const res = await axios.post(`${server}/api/users/create`, newUserDetails, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      console.log(error.response);
      return {
        error: true,
        status: error.response.status,
        message: error.response.data.error,
      };
    }
  }
};

export const getUserDetailsByIdReq = async (access_token, id) => {
  try {
    const res = await axios.get(`${server}/api/users/${id}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      return {
        error: true,
        status: error.response.status,
      };
    }
  }
};

export const editUserReq = async (access_token, id, newUserDetails) => {
  try {
    const res = await axios.post(`${server}/api/users/edit/${id}`, newUserDetails, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      console.log(error.response);
      return {
        error: true,
        status: error.response.status,
        message: error.response.data.error,
      };
    }
  }
};

export const deleteUserReq = async (access_token, id) => {
  try {
    const res = await axios.delete(`${server}/api/users/delete/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      console.log(error.response);
      return {
        error: true,
        status: error.response.status,
        message: error.response.data.error,
      };
    }
  }
};

export const evetnReq = async (access_token, page, limit) => {
  try {
    const res = await axios.get(`${server}/api/event?page=${page}&limit=${limit}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      return {
        error: true,
        status: error.response.status,
      };
    }
  }
};

export const analyticsCountReq = async (access_token, affiliateCode) => {
  try {
    const res = await axios.post(
      `${server}/api/event/count`,
      { affiliateCode: affiliateCode },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      return {
        error: true,
        status: error.response.status,
      };
    }
  }
};

export const countPerDayReq = async (access_token, affiliateCode) => {
  try {
    const res = await axios.post(
      `${server}/api/event/perday`,
      { affiliateCode: affiliateCode },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    if (error.response) {
      return {
        error: true,
        status: error.response.status,
      };
    }
  }
};
