import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import CreateUser from "./CreateUser";
import EditUser from "./EditUser";
import Home from "./Home";
import Analytics from "./Analytics";

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/analytics/:id">
          <Analytics />
        </Route>
        <Route path="/create">
          <CreateUser />
        </Route>
        <Route path="/edit/:id">
          <EditUser />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
