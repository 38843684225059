import React, { useState } from "react";

import Login from "./Login";
import DashBoard from "./Dashboard";

import useStore from "./Store";

function App() {
  const profile = useStore((s) => s.profile);

  return <div className="App">{profile ? <DashBoard /> : <Login />}</div>;
}

export default App;
