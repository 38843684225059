import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React from "react";

const UserDetails = ({ user }) => {
  return (
    <MDBRow>
      <MDBCol size="6">
        <MDBRow>
          <MDBCol size="6">
            <span className="text-white">First Name:</span>
          </MDBCol>
          <MDBCol size="6">
            <span>{user.first_name}</span>
          </MDBCol>
          <MDBCol size="6">
            <span className="text-white">Last Name:</span>
          </MDBCol>
          <MDBCol size="6">
            <span>{user.last_name}</span>
          </MDBCol>
          <MDBCol size="6">
            <span className="text-white">Email:</span>
          </MDBCol>
          <MDBCol size="6">
            <span>{user.email}</span>
          </MDBCol>
          <MDBCol size="6">
            <span className="text-white">Affiliate Code:</span>
          </MDBCol>
          <MDBCol size="6">
            <span>{user.affiliateCode}</span>
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default UserDetails;
