import { MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdb-react-ui-kit";
import React from "react";
import Header from "./components/Header";
import EditUserForm from "./components/EditUserForm";
import PageHeader from "./components/PageHeader";
import SectionWrapper from "./components/SectionWrapper";

const EditUser = () => {
  return (
    <>
      <Header />
      <PageHeader to="/" title="Edit User" />
      <SectionWrapper>
        <MDBRow className="justify-content-start">
          <MDBCol size="12">
            <EditUserForm />
          </MDBCol>
        </MDBRow>
      </SectionWrapper>
    </>
  );
};

export default EditUser;
