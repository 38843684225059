import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "./components/Header";
import PageHeader from "./components/PageHeader";
import SectionWrapper from "./components/SectionWrapper";
import UserDetails from "./components/UserDetails";
import Summary from "./components/Summary";
import AnalyticsCharts from "./components/AnalyticsChart";
import useStore from "./Store";
import { getUserDetailsByIdReq } from "./Request";

const Analytics = () => {
  const { id } = useParams();
  const access_token = useStore((s) => s.access_token);

  const [user, setUser] = useState(null);

  const fetchUserById = async () => {
    const fetchedUser = await getUserDetailsByIdReq(access_token, id);

    if (fetchedUser.error) {
      //   setError("Something went wrong!");
      //   setSuccess(null);
      return;
    }

    setUser(fetchedUser);
  };

  useEffect(() => {
    fetchUserById();
  }, []);

  return (
    <>
      <Header />
      <PageHeader to="/" title="Affiliate Snapshot" />
      <SectionWrapper title="User Details">
        {user && <UserDetails user={user} />}
      </SectionWrapper>
      {user && <Summary user={user} />}
      {user && <AnalyticsCharts user={user} />}
    </>
  );
};

export default Analytics;
