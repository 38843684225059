import React, { useCallback } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  // MDBDropdown,
  // MDBDropdownToggle,
  // MDBDropdownMenu,
  // MDBDropdownItem,
  // MDBDropdownLink,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";

import useStore from "../Store";

export default function () {
  const set = useStore((s) => s.set);
  const first_name = useStore((s) => s.profile?.first_name);
  const last_name = useStore((s) => s.profile?.last_name);

  const handleLogout = useCallback(() => {
    set((s) => {
      s.access_token = null;
      s.profile = null;
    });
  }, []);

  return (
    <header>
      <MDBContainer>
        <div className="py-3 border-bottom border-gray">
          <MDBRow>
            <MDBCol
              size="1"
              className="header-col__logo d-flex justify-content-start align-items-center"
            >
              <Link to="/">
                <img className="logo" src={logo} />
              </Link>
            </MDBCol>
            <MDBCol
              size="5"
              className="header-col__logo d-flex justify-content-start align-items-center"
            >
              <h5 className="fw-bold text-white ps-3 ms-3 left-white">Admin Dashboard</h5>
            </MDBCol>
            <MDBCol
              size="6"
              className="header-col__button d-flex justify-content-end align-items-center"
            >
              {/* <MDBDropdown className="shadow-0">
                <MDBDropdownToggle color="light" className="shadow-0" noRipple>
                  Howdy, {first_name}
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem>
                    <MDBDropdownLink href="#">Action</MDBDropdownLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownLink href="#">Another action</MDBDropdownLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownLink href="#">
                      Something else here
                    </MDBDropdownLink>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown> */}
              <p className="mb-0 mx-4 fw-bold">{first_name} {last_name}</p>
              <MDBBtn
                className="fw-bold btn-pink"
                onClick={handleLogout}
              >
                <i className="fa fa-arrow-right pe-1"></i>
                Logout
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </div>
      </MDBContainer>
    </header>
  );
}
