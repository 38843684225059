import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import React from "react";
import { Link } from "react-router-dom";

const PageHeader = ({ to, title }) => {
  return (
    <section className="py-4">
      <MDBContainer>
        <MDBRow>
          <MDBCol>
            <div className="d-flex align-items-center">
              <Link to={to}>
                <MDBBtn
                  className="fw-bold btn-black"
                >
                  <i className="fas fa-chevron-left"></i>&nbsp;Back
                </MDBBtn>
              </Link>
              <MDBTypography variant="h4" className="mb-0 ms-4 text-white">
                {title}
              </MDBTypography>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
};

export default PageHeader;
