import React, { useEffect } from "react";
import Router from "./Router";

import { profileReq } from "./Request";

import useStore from "./Store";

const Dashboard = () => {
  const set = useStore((s) => s.set);
  const access_token = useStore((s) => s.access_token);

  const fetchProfile = async () => {

    const pro = await profileReq(access_token);
    if(!pro.error){
      
      set((s) => {
        s.profile = pro;
      });
    }else if(pro?.status===401){
      
      set((s) => {
        s.profile = null;
        s.access_token = null;
      });
      localStorage.clear()
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <>
      <Router />
    </>
  );
};

export default Dashboard;
