import {
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBSpinner,
  MDBTypography,
  MDBValidation,
} from "mdb-react-ui-kit";
import React, { useCallback, useEffect, useState } from "react";

import useStore from "../Store";
import { getUserDetailsByIdReq, editUserReq, deleteUserReq } from "../Request";
import { useParams } from "react-router-dom";

const EditUserForm = () => {
  const { id } = useParams();
  const access_token = useStore((s) => s.access_token);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [basicModal, setBasicModal] = useState(false);

  const initialFormValues = {
    first_name: "",
    last_name: "",
    email: "",
    affiliateCode: "",
    role: "user",
    commission: "10",
    commission_type: "percentage",
    oldAffiliateCode: "",
  };

  const [formData, setFormData] = useState(initialFormValues);

  const fetchUserById = async () => {
    const fetchedUser = await getUserDetailsByIdReq(access_token, id);

    if (fetchedUser.error) {
      setError("Something went wrong!");
      setSuccess(null);
      return;
    }

    setError(null);

    console.log("fetchedUser", fetchedUser);
    setFormData({
      first_name: fetchedUser.first_name,
      last_name: fetchedUser.last_name,
      email: fetchedUser.email,
      affiliateCode: fetchedUser.affiliateCode,
      commission: fetchedUser.commission ?? "10",
      commission_type: fetchedUser.commission_type ?? "percentage",
      role: "user",
      oldAffiliateCode: fetchedUser.affiliateCode
    });
  };

  useEffect(() => {
    fetchUserById();
  }, []);

  const changeValue = useCallback(
    (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData]
  );
  
  const saveEditedUser = async () => {
    setLoading(true);
    //console.log('f', formData)
    const createdUser = await editUserReq(access_token, id, formData);

    if (createdUser.error) {
      setError(createdUser.message);
      setSuccess(null);
      setLoading(false);
      return;
    }

    setError(null);
    setSuccess("User Updated successfully!");
    // setFormData(initialFormValues);
    setLoading(false);
  };

  const handleSubmit = useCallback(() => {
    saveEditedUser();
  }, [formData]);

  const toggleShow = useCallback(() => {
    setBasicModal((basicModal) => !basicModal);
  }, []);

  const deleteUser = async () => {
    setLoading(true);
    setBasicModal(false);
    const deleteduser = deleteUserReq(access_token, id);

    if (deleteduser.error) {
      setError(deleteduser.message);
      setSuccess(null);
      setLoading(false);
      return;
    }

    setError(null);
    setSuccess("User Deleted successfully!");
    setFormData(initialFormValues);
    setLoading(false);
  };

  return (
    <MDBValidation className="row g-3 user-input-form" noValidate onSubmit={handleSubmit}>
      <MDBCol size="12">
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        {success && (
          <div className="alert alert-success" role="alert">
            {success}
          </div>
        )}
      </MDBCol>
      <MDBCol size="6">
        <MDBInput
          label="First Name"
          id="createUserFirstName"
          type="text"
          name="first_name"
          value={formData.first_name}
          onChange={changeValue}
        />
      </MDBCol>
      <MDBCol size="6">
        <MDBInput
          label="Last Name"
          id="form1"
          type="text"
          name="last_name"
          value={formData.last_name}
          onChange={changeValue}
        />
      </MDBCol>
      <MDBCol size="12">
        <MDBInput
          label="Email"
          id="form1"
          type="email"
          name="email"
          value={formData.email}
          onChange={changeValue}
          validation="Required"
          required
          invalid
        />
      </MDBCol>
      <MDBCol size="6">
        <MDBInput
          label="Affiliate Code"
          id="form1"
          type="text"
          name="affiliateCode"
          value={formData.affiliateCode}
          onChange={changeValue}
          validation="Required"
          required
          invalid
        />
      </MDBCol>

      <MDBCol size="6">
        <div style={{ position: "relative" }}>
          <MDBInput
            label="Commission"
            id="form1"
            type="text"
            name="commission"
            value={formData.commission}
            onChange={changeValue}
            validation="Required"
            required
            invalid
          />
          <select
            className="inline-select"
            value={formData.commission_type}
            onChange={changeValue}
            name="commission_type"
          >
            <option value="percentage">&#37;</option>
            <option value="flat">&#36;</option>
          </select>
        </div>
      </MDBCol>
      
      <MDBCol size="6">
        
      </MDBCol>
      <MDBCol size="6 text-end">
        <MDBBtn
          type="button"
          className="btn-black"
          disabled={loading}
          onClick={() => {
            setBasicModal(true);
          }}
        >
          {!loading ? "Delete" : "Deleting..."}
        </MDBBtn>
        <MDBBtn className="btn-pink ms-3" type="submit" disabled={loading}>
          {!loading ? "Save" : "Saving..."}
        </MDBBtn>
      </MDBCol>

      <MDBModal
        show={basicModal}
        getOpenState={(e) => setBasicModal(e)}
        tabIndex="-1"
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader className="bg-dark text-white d-flex justify-content-center">
              <MDBModalTitle>Are you sure?</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBTypography className="text-dark" variant="p">
                Are you sure to delete this user?
              </MDBTypography>
              <MDBTypography className="text-dark" variant="p">
                This action is irreversible.
              </MDBTypography>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn className="btn-black" onClick={toggleShow}>No</MDBBtn>
              <MDBBtn className="btn-pink" onClick={deleteUser}>
                Yes
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </MDBValidation>
  );
};

export default EditUserForm;
