import create from "zustand";
import produce from "immer";
import { persist } from "zustand/middleware";

// Create a function to initialize state from localStorage
const initialState = () => {
  const storedState = localStorage.getItem("zustand-state");
  return storedState ? JSON.parse(storedState) : {
    access_token:null,
    profile: null,
    loggedIn: false,
    page: "dashboard",
    users: [],
    currentPage: 1,
    limit: 10,
    lastPage: 1,
    total: 0,
  };
};

const useStore = create(
  persist(
    (set) => ({
      ...initialState(),
      set: (fn) => set(produce(fn)),
    }),
    {
      name: "zustand-state", // Set a unique name for your persisted state
      getStorage: () => localStorage, // Choose the storage engine (localStorage, sessionStorage, etc.)
      partialize: (persistedState) => ({
        profile: persistedState.profile,
        loggedIn: persistedState.loggedIn,
        access_token: persistedState.access_token,
      }), // Specify the properties to persist
    }
  )
);

export default useStore;
