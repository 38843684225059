import React, { useEffect, useState } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBTypography } from "mdb-react-ui-kit";
// import PerDayChart from "./PerDayChart";
import useStore from "../Store";
import { analyticsCountReq, countPerDayReq } from "../Request";

const Summary = ({ user }) => {
  const access_token = useStore((s) => s.access_token);
  const affiliateCode = user.affiliateCode;

  const [analyticsCount, setAnalyticsCount] = useState([]);

  const getCount = async () => {
    const fetchCounts = await analyticsCountReq(access_token, affiliateCode);
    if (fetchCounts.error) return;

    setAnalyticsCount(fetchCounts);
  };

  //   const getPerdayCount = async () => {
  //     const fetchPerDayCount = await countPerDayReq(access_token, affiliateCode);
  //     if (fetchPerDayCount.error) return;

  //     set((s) => {
  //       s.eventsPerDay = fetchPerDayCount.event;
  //       s.salePerDay = fetchPerDayCount.sale;
  //     });
  //   };

  useEffect(() => {
    getCount();
    // affiliateCode && getPerdayCount();
  }, []);

  return (
    <section className="py-5">
      <div className="header mb-4 text-md-start">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <h4 className="text-white">Affiliate Snapshot</h4>
              <p>Here's a breakdown of this user's activity.</p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
      <div className="mb-5">
        <MDBContainer>
          <MDBRow>
            {analyticsCount &&
              analyticsCount.map((ac, i) => (
                <MDBCol size="3" key={i}>
                  <div className={`p-3 shadow-5 text-md-start pb-4 rounded-3 bg-white summary-box tile-box-${i}`}>
                    <MDBTypography tag="h4" className="mb-0 text-white fw-bold">
                    {
                      ac.type === "totalRevenue" || ac.type === "totalCommission" ? "$" + ac.count?.toFixed(2) : "" + ac.count
                    }
                    </MDBTypography>
                    {ac.type === "totalOrders" && (
                      <span className="">Total Products Purchased</span>
                    )}
                    {ac.type === "pageview" && (
                      <span className="">Total Page Visit</span>
                    )}
                    {ac.type === "totalRevenue" && (
                      <span className="">Total Revenue Generated</span>
                    )}
                    {ac.type === "totalCommission" && (
                      <span className="">Net Commission</span>
                    )}
                  </div>
                </MDBCol>
              ))}
          </MDBRow>
        </MDBContainer>
      </div>
      {/* <PerDayChart salePerDay={salePerDay} eventsPerDay={eventsPerDay} /> */}
    </section>
  );
};

export default Summary;
