import React from "react";
import AllUser from "./components/AllUser";
import Header from "./components/Header";

const Home = () => {
  return (
    <>
      <Header />
      <AllUser />
    </>
  );
};

export default Home;
