import { MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { countPerDayReq } from "../Request";
import useStore from "../Store";
import Chart from "./Chart";

function AnalyticsCharts({ user }) {
  const access_token = useStore((s) => s.access_token);
  const affiliateCode = user.affiliateCode;

  const [salePerDay, setSalePerDay] = useState([]);
  const [eventsPerDay, setEventsPerday] = useState([]);

  const getPerdayCount = async () => {
    const fetchPerDayCount = await countPerDayReq(access_token, affiliateCode);

    if (fetchPerDayCount.error) return;

    setEventsPerday(fetchPerDayCount.event);
    setSalePerDay(fetchPerDayCount.sale);
  };

  useEffect(() => {
    getPerdayCount();
  }, []);

  return (
    <div className="text-md-start mb-5">
      <MDBContainer>
        <MDBRow className="g-3">
          <MDBCol size="12" md="6">
            <div className="shadow-5 p-3 rounded-3 bg-dark">
              <MDBTypography tag="h5" className="text-white">
                Commissions Breakdown
              </MDBTypography>
              <Chart perDayData={salePerDay} label="Sale" />
            </div>
          </MDBCol>
          <MDBCol size="12" md="6">
            <div className="shadow-5 p-3 rounded-3 bg-dark">
              <MDBTypography tag="h5" className="text-white">
                Clicks Breakdown
              </MDBTypography>
              <Chart perDayData={eventsPerDay} label="Event" />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}

export default AnalyticsCharts;
