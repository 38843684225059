import React from "react";
import { MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

const UserItem = ({ user }) => {
  return (
    <MDBRow className="border-bottom border-gray history-item align-items-center">
      <MDBCol size="2" className="py-3">
        <span className="text-white">{user.first_name || ""}</span>
      </MDBCol>
      <MDBCol size="2" className="py-3">
        <span className="text-white">{user.last_name || ""}</span>
      </MDBCol>
      <MDBCol size="3" className="py-3">
        <span className="text-white">{user.email}</span>
      </MDBCol>
      <MDBCol size="2" className="py-3">
        <span className="text-white">{user.affiliateCode}</span>
      </MDBCol>
      <MDBCol size="3" className="py-3 text-center">
        <Link className="d-inline-block me-2" to={`/edit/${user._id}`}>
          <MDBBtn className="text-white" outline size="sm" style={{ minWidth: "100px" }}>
            Edit
          </MDBBtn>
        </Link>
        <Link className="d-inline-block" to={`/analytics/${user._id}`}>
          <MDBBtn size="sm" className="btn-pink" style={{ minWidth: "100px" }}>
            Details
          </MDBBtn>
        </Link>
      </MDBCol>
    </MDBRow>
  );
};

export default UserItem;
