import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBInputGroup,
  MDBInputGroupElement,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useStore from "../Store";
import UserItem from "./UserItem";
import TableUtilities from "./TableUtilities";
import { allUsersReq } from "../Request";
import SectionWrapper from "./SectionWrapper";

const AllUser = () => {
  const set = useStore((s) => s.set);
  const access_token = useStore((s) => s.access_token);
  const users = useStore((s) => s.users);
  const page = useStore((s) => s.page);
  const lastPage = useStore((s) => s.lastPage);
  const limit = useStore((s) => s.limit);
  const total = useStore((s) => s.total);
  const search = useStore((s) => s.search);
  const fetchAllUser = async () => {
    const fetchedUsers = await allUsersReq(access_token, search, page, limit);
    if (fetchedUsers.error) return;

    // update states
    set((s) => {
      s.users = fetchedUsers.data;
      s.page = fetchedUsers.page;
      s.lastPage = fetchedUsers.lastPage;
      s.total = fetchedUsers.total;
      
    });
  };

  useEffect(() => {
    fetchAllUser();
    setSearchValue(search);
  }, [search, page, limit]);

  const nextPage = useCallback(() => {
    if (page < lastPage) {
      set((s) => {
        s.page = page + 1;
      });
    }
  }, [page, lastPage]);

  const prevPage = useCallback(() => {
    if (page > 1) {
      set((s) => {
        s.page = page - 1;
      });
    }
  }, [page]);

  const changeLimit = useCallback((e) => {
    const currentLimit = e.target.value;
    set((s) => {
      s.limit = currentLimit;
    });
  }, []);

  const [searchValue, setSearchValue] = useState("");
  const changeSearchValue = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  const initSearch = useCallback(() => {
    set((s) => {
      s.search = searchValue.trim();
      s.page = 1;
    });
  }, [searchValue]);

  const clearSearch = useCallback(() => {
    setSearchValue("");
    set((s) => {
      s.search = "";
      s.page = 1;
    });
  }, []);

  return (
    <SectionWrapper>
      <MDBRow className="mb-4 align-items-center">
        <MDBCol size="12">
          <div className="my-3">
            <Link to="/create" className="d-inline-block">
              <MDBBtn className="text-white btn-pink">Create New User</MDBBtn>
            </Link>
          </div>
        </MDBCol>
        <MDBCol size="4">
          <MDBInputGroup className="mb-3 ">
            <MDBInputGroupElement
              placeholder="Search By Email"
              type="text"
              value={searchValue}
              onChange={changeSearchValue}
            />
            {!search && <MDBBtn className="btn-pink" onClick={initSearch}>Search</MDBBtn>}
            {search && <MDBBtn className="btn-black" onClick={clearSearch}>Clear</MDBBtn>}
          </MDBInputGroup>
        </MDBCol>
        <MDBCol size="8">
          <TableUtilities
            nextPage={nextPage}
            previousPage={prevPage}
            changeLimit={changeLimit}
            limit={limit}
            page={page}
            pageLength={users?.length}
            totalCount={total}
            lastPage={lastPage}
          />
        </MDBCol>
      </MDBRow>

      <MDBRow className="border-bottom border-dark">
        <MDBCol size="2" className="py-2">
          <span className="text-white ">First Name</span>
        </MDBCol>
        <MDBCol size="2" className="py-2">
          <span className="text-white ">Last Name</span>
        </MDBCol>
        <MDBCol size="3" className="py-2">
          <span className="text-white ">Email</span>
        </MDBCol>
        <MDBCol size="2" className="py-2">
          <span className="text-white ">Affiliate Code</span>
        </MDBCol>
        <MDBCol size="3" className="py-2 text-center">
          <span className="text-white ">Actions</span>
        </MDBCol>
      </MDBRow>

      {users && users?.map((us, i) => <UserItem user={us} key={i} />)}

      <MDBRow className="mt-4">
        <MDBCol></MDBCol>
        <MDBCol>
          <TableUtilities
            nextPage={nextPage}
            previousPage={prevPage}
            changeLimit={changeLimit}
            limit={limit}
            page={page}
            pageLength={users?.length}
            totalCount={total}
            lastPage={lastPage}
          />
        </MDBCol>
      </MDBRow>
    </SectionWrapper>
  );
};

export default AllUser;
