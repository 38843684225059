import { MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdb-react-ui-kit";
import React from "react";
import Header from "./components/Header";
import CreateUserForm from "./components/CreateUserForm";
import PageHeader from "./components/PageHeader";
import SectionWrapper from "./components/SectionWrapper";

const CreateUser = () => {
  return (
    <>
      <Header />
      <PageHeader to="/" title="Create New User" />
      <SectionWrapper>
        <MDBRow className="justify-content-start">
          <MDBCol size="12">
            <CreateUserForm />
          </MDBCol>
        </MDBRow>
      </SectionWrapper>
    </>
  );
};

export default CreateUser;
