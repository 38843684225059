import React, { memo, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

function Chart({ perDayData = [], label }) {
  const [currentData, setCurrentData] = useState([])

  let width, height, gradient;
  function getGradient(ctx, chartArea) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
      gradient.addColorStop(0, "#00A7CD");
      gradient.addColorStop(0.5, "#00A7CD");
      gradient.addColorStop(1, "#EC1B78");
    }

    return gradient;
  }
  const data = {
    labels: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    datasets: [
      {
        label: label,
        data: [0, 0, 0, 0, 0, 0, 0],
        borderColor: function(context) {
          const chart = context.chart;
          const {ctx, chartArea} = chart;
  
          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return getGradient(ctx, chartArea);
        },
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    }
  };


  data.labels.forEach((item, i) => {
    const dayData = perDayData.find((p) => p._id === i + 1);
    dayData
      ? (data.datasets[0].data[i] = dayData.count)
      : (data.datasets[0].data[i] = 0);
  });

  useEffect(() => {
    if (!perDayData) return;
    data.labels.forEach((item, i) => {
      const dayData = perDayData.find((p) => p._id === i + 1);
      dayData
        ? (data.datasets[0].data[i] = dayData.count)
        : (data.datasets[0].data[i] = 0);
    });
    let charts = document.getElementsByClassName("line-charts");
    if(charts.length > 1){
      for(const el of charts){
        //el.remove()
      }
    } 
    console.log(data, document.getElementsByClassName("line-charts"));
    setCurrentData(data)

  }, [perDayData]); 

  return (
    <div className="line-charts">
      {currentData != "" ?<Line data={currentData} options={options} />: ""} 
    </div>
  );
}

export default Chart;
